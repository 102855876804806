<template>
  <div class="h-100">
    <query-form
      class="content_block"
      label-width="100px"
      @search="handleSearch"
      :span="8"
      @reset="handleReset"
      :defaultCollapsed="false"
    >
      <el-form-item label="备件名称：">
        <el-input
          v-model.trim="query.name"
          clearable
          placeholder="请输入备件名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="备件编码：">
        <el-input
          v-model.trim="query.code"
          clearable
          placeholder="请输入备件编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="备件类型：">
        <el-row type="flex">
          <SpareTypesSelectors :name="sparePartTypeName" :isQuery="true" style="flex: 1" @change="(v) => handleTypeChange(v, 'form')" />
          <i v-auth="'sparePart:type:manager'" class="el-icon-setting" title="管理备件类型" @click="navigateTo">
          </i>
        </el-row>
      </el-form-item>
      <el-form-item label="是否医疗件：">
        <el-select v-model="query.isMedical" placeholder="请选择是否医疗件，默认全部" @change="handleSearch">
          <el-option
            v-for="t in withAll()"
            :value="t.value"
            :label="t.label"
            :key="t.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="所属设备：">
        <MultiDeviceSelector :devices="formDevices" @change="(v) => handleDeviceId(v, 'form')" />
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker
          v-model="times"
          type="daterange"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="tableList"
      class="content_block"
      id="sparepartsTable"
      show-right-toolbar
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
      :leftToolbar="tableButtons"
    />
    <el-dialog
      width="720px"
      :title="`${type === 'add' ? '新增' : '编辑'}备件`"
      :visible.sync="deviceDialogVisible"
    >
      <el-form
        label-width="120px"
        ref="deviceForm"
        :rules="deviceFormRules"
        :model="deviceForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="备件名称：" prop="name">
              <el-input
                v-model.trim="deviceForm.name"
                clearable
                :readonly="type !== 'add'"
                placeholder="请输入备件名称"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备件类型：" prop="sparePartTypeId">
              <SpareTypesSelectors :name="deviceForm.sparePartTypeName" @change="(v) => handleTypeChange(v, 'dialog')" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="规格型号：" prop="spec">
              <el-input
                v-model.trim="deviceForm.spec"
                clearable
                :readonly="type !== 'add'"
                placeholder="请输入规格型号"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位：">
              <el-input
                v-model.trim="deviceForm.unit"
                clearable
                placeholder="请输入单位"
                :maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备件编码：">
              <el-input
                v-model.trim="deviceForm.code"
                clearable
                placeholder="请输入备件编码"
                :maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属设备：">
              <MultiDeviceSelector :devices="devices" @change="(v) => handleDeviceId(v, 'dialog')" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否医疗件：" prop="isMedical">
              <el-select v-model="deviceForm.isMedical" placeholder="请选择是否医疗件">
                <el-option
                  v-for="t in medicalOptions"
                  :value="t.value"
                  :label="t.label"
                  :key="t.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出库价：" prop="deliveryPrice">
              <el-input-number
                :min="0.01"
                :precision="2"
                placeholder="请输入出库价"
                v-model.trim="deviceForm.deliveryPrice"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="销售价：" prop="salePrice">
              <el-input-number
                :min="0.01"
                :precision="2"
                placeholder="请输入销售价"
                v-model.trim="deviceForm.salePrice"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-show="deviceForm.isMedical === '1'">
            <el-form-item label="医疗件标准分类：" label-width="140px">
              <MedicalTree :name="deviceForm.medicalStandardClassificationName" @change="handleMedical" />
            </el-form-item>
        </el-row>
        <el-row v-show="deviceForm.isMedical === '1'">
            <el-form-item label="注册/备案凭证号：" label-width="140px">
              <el-input
                v-model.trim="deviceForm.registerCode"
                clearable
                placeholder="请输入注册/备案凭证号"
                :maxlength="50"
              ></el-input>
            </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="图片：">
            <Upload
              v-model.trim="fileLists"
              label="品牌LOGO"
              @success="handleUpload"
              @remove="handleRemove"
              :maxSize="5"
              size="small"
              :limit="10"
            />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="详情说明：">
            <el-input
              v-model.trim="deviceForm.detailedInformation"
              clearable
              placeholder="请输入详情说明"
              type="textarea"
              :maxlength="800"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="deviceDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSaveDevice">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { searchSpareParts, addSpareDevice, getSpareDeviceDetail, updateSpareDevice } from '@/api/spareParts';
import SpareTypesSelectors from '@/components/spareTypeSlelector';
import MultiDeviceSelector from '@/components/multiDeviceSelector';
import Upload from '@/components/common/upload';
import MedicalTree from '@/components/medicalTree';

export default {
  name: 'SPAREPARTS_INDEX',
  mixins: [TablePaginationMixin],
  components: {
    SpareTypesSelectors,
    MultiDeviceSelector,
    Upload,
    MedicalTree,
  },
  data () {
    return {
      tableList: [],
      columnList: [
        {
          tooltip: true,
          label: '备件名称',
          minWidth: '140px',
          prop: 'name',
          render: (h, { row }) => {
            const isPermission = this.actionPermissionMap && this.actionPermissionMap['sparePart:detail'];
            let clickFn = isPermission ? this.handleActionClick.bind(this, row, 'detail') : () => {};
            let classObj = {'text-primary': isPermission, 'cursor-pointer': isPermission};

            let actions = [];
            actions.push(
              <span
                class={classObj}
                onClick={clickFn}
              >
                {row.name}
              </span>,
            );
            return <div>{...actions}</div>;
          },
        },
        {
          tooltip: true,
          label: '备件类型',
          minWidth: '140px',
          prop: 'sparePartTypeName',
        },
        {
          tooltip: true,
          label: '是否医疗件',
          minWidth: '140px',
          prop: 'isMedicalStr',
        },
        {
          tooltip: true,
          label: '规格型号',
          minWidth: '140px',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '备件编码',
          minWidth: '140px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '所属设备',
          minWidth: '140px',
          prop: 'device',
          format: (val) => {
            val = val || [];

            const names = val.map(v => v.name);

            return names.join(',');
          },
        },
        {
          tooltip: true,
          label: '单位',
          minWidth: '140px',
          prop: 'unit',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '140px',
          prop: 'createTime',
        },
        {
          tooltip: true,
          label: '最后操作时间',
          minWidth: '140px',
          prop: 'lastUpdateTime',
        },
        {
          label: '操作',
          minWidth: '180px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];
            actions.push(
              <span
                v-auth={'sparePart:update'}
                class="cursor-pointer text-primary ml-1 "
                onClick={this.handleActionClick.bind(this, row, 'edit')}
              >
                编辑
              </span>,
            );
            return <div>{...actions}</div>;
          },
        },
      ],
      sparePartTypeName: '全部',
      query: {
        name: '',
        code: '',
        sparePartTypeId: '',
        isMedical: '',
        startTime: '',
        endTime: '',
        sparePartDeviceId: [],
      },
      times: [],
      medicalOptions: [
        {
          label: '是',
          value: '1',
        },
        {
          label: '否',
          value: '0',
        },
      ],
      deviceForm: {
        name: '',
        sparePartTypeId: '',
        sparePartTypeName: '',
        spec: '',
        unit: '',
        code: '',
        device: [],
        isMedical: '',
        deliveryPrice: '',
        salePrice: '',
        medicalStandardClassificationId: '',
        medicalStandardClassificationName: '',
        registerCode: '',
        picture: [],
        detailedInformation: '',
      },
      deviceDialogVisible: false,
      deviceFormRules: {
        name: [
          { required: true, message: '请输入备件名称', trigger: 'blur' },
        ],
        sparePartTypeId: [
          { required: true, message: '请选择备件类型', trigger: 'change' },
        ],
        spec: [
          { required: true, message: '请输入规格型号', trigger: 'blur' },
        ],
        isMedical: [
          { required: true, message: '请选择是否医疗件', trigger: 'change' },
        ],
        deliveryPrice: [
          { required: true, message: '请输入出库价', trigger: 'blur' },
        ],
        salePrice: [
          { required: true, message: '请输入销售价', trigger: 'blur' },
        ],
      },
      fileLists: [],
      type: 'add',
      devices: [],
      formDevices: [],
    };
  },
  methods: {
    withAll () {
      return [
        {
          label: '全部',
          value: '',
        },
        ...this.medicalOptions,
      ];
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        name: '',
        code: '',
        sparePartTypeId: '',
        isMedical: '',
        startTime: '',
        endTime: '',
        sparePartDeviceId: [],
      };

      this.sparePartTypeName = '全部';
      this.formDevices = [];

      this.times = [];
      this.handleSearch();
    },
    getTableList () {
      this.query.startTime = this.times[0] || '';
      this.query.endTime = this.times[1] || '';

      searchSpareParts({
        ...this.query,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      }).then((res) => {
        this.tableList = res.body?.list || [];
        this.pagination.total = res.body?.total || 0;
      });
    },

    getSpareDeviceDetail (id) {
      getSpareDeviceDetail({id}).then((res) => {
        if (res.body) {
          this.deviceForm = res.body;

          this.deviceForm.isMedical = this.deviceForm.isMedical + '';

          this.fileLists = [];
          if (res.body?.picture) {
            this.fileLists = res.body.picture.map((v) => ({
              url: v.url,
              annexName: v.attachName,
            }));
          }

          this.devices = this.deviceForm.device.map((v) => ({
            name: v.name,
            id: v.deviceId,
          }));
        }
      });
    },
    handleActionClick (item, action) {
      if (action === 'edit') {
        this.deviceDialogVisible = true;
        this.type = 'edit';

        this.$nextTick(() => {
          this.$refs.deviceForm.resetFields();
          this.getSpareDeviceDetail(item.id);
        });
      } else if (action === 'detail') {
        this.$router.push({
          name: 'SPAREPART_DETAIL',
          query: {
            id: item.id,
          },
        });
      }
    },
    handleTypeChange (v, type) {
      if (type === 'form') {
        this.query.sparePartTypeId = v.id;
        this.sparePartTypeName = v.name;
        this.handleSearch();
      } else if (type === 'dialog') {
        this.deviceForm.sparePartTypeId = v.id;
        this.deviceForm.sparePartTypeName = v.name;
      }
    },
    handleDeviceId (v, type) {
      if (type === 'form') {
        this.query.sparePartDeviceId = v.map((v) => v.id);
        this.handleSearch();
      } else {
        this.deviceForm.device = v.map((i) => ({
          deviceId: i.id,
          name: i.name,
        }));
      }
    },
    handleMedical (v) {
      this.deviceForm.medicalStandardClassificationId = v.id;
      this.deviceForm.medicalStandardClassificationName = v.name;
    },
    handleUpload (file) {
      this.fileLists.push(file);
    },
    handleRemove (file) {
      const index = this.fileLists.findIndex(v => v.uid === file.uid);

      this.fileLists.splice(index, 1);
    },
    handleSaveDevice () {
      this.$refs.deviceForm.validate((valid) => {
        if (valid) {
          const api = this.type === 'add' ? addSpareDevice : updateSpareDevice;
          const msg = this.type === 'add' ? '新增' : '编辑';

          let params = {
            ...this.deviceForm,
          };

          if (params.isMedical !== '1') {
            params.registerCode = '';
            params.medicalStandardClassificationId = '';
            params.medicalStandardClassificationName = '';
          }

          params.picture = this.fileLists.map(v => ({
            annexName: v.attachName,
            url: v.url,
          }));

          api(params).then(() => {
            this.deviceDialogVisible = false;
            this.$message({
              message: `${msg}成功`,
              type: 'success',
            });
            this.handleReset();
          });
        }
      });
    },
    navigateTo () {
      this.$router.push({
        name: 'SPAREPART_TYPE',
      });
    },
  },
  computed: {
    ...mapGetters(['actionPermissionMap']),
    tableButtons () {
      let actions = [
        {
          name: '新增',
          status: 'primary',
          size: 'small',
          icon: 'el-icon-plus',
          permission: 'sparePart:add',
          click: () => {
            this.deviceDialogVisible = true;
            this.type = 'add';
            this.devices = [];
            this.fileLists = [];

            this.deviceForm = {
              name: '',
              sparePartTypeId: '',
              sparePartTypeName: '',
              spec: '',
              unit: '',
              code: '',
              device: [],
              isMedical: '',
              deliveryPrice: '',
              salePrice: '',
              medicalStandardClassificationId: '',
              medicalStandardClassificationName: '',
              registerCode: '',
              picture: [],
              detailedInformation: '',
            };

            this.$nextTick(() => {
              this.$refs.deviceForm.resetFields();
            });
          },
        },
      ];
      return actions;
    },
  },
};
</script>
<style scoped lang="scss">
.el-icon-setting {
  font-size: 28px;
  margin-left: 8px;
  border: 1px #dcdff6 solid;
  border-radius: 4px;
  color: #dcdff6;
  height: 32px;
  line-height: 32px;
  cursor: pointer;

  &:hover {
    background-color: #409eff;
    border-color: #409eff;
  }
}

.el-date-editor--daterange.el-input__inner {
  width: 100%;
}
</style>
